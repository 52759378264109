import React, { useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { FaDownload, FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa';
import '../styles.css';

const Home = () => {
  useEffect(() => {
    // Dynamically load the LightWidget script
    const script = document.createElement('script');
    script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-b, #0d3b66, #3a86ff)" // Matching gradient
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
      pb={24} // Add extra padding at the bottom
    >
      <h1 className="game-title">
        <span className="main-title">Sub Zero</span>
        <span className="sub-title"> Humanity's Last Hope</span>
      </h1>

      {/* Sub Zero: The Sequel Game Section */}
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color="white"
        p={8}
        mt={8}
      >
        <Box mb={4}>
          <img
            src="/images/icon_transparent.png"
            alt="Sub Zero Icon"
            style={{ width: '150px', height: '150px' }}
          />
        </Box>

        <h2 className="section-header" style={{ fontSize: '28px', marginBottom: '20px' }}>
          The Sequel Has Arrived: <strong>Sub Zero: The Game</strong>
        </h2>

        <Box className="promo-text" maxW="600px" textAlign="center" mb={6} lineHeight="1.8">
          <p>
            <strong>Sub Zero</strong> is the highly anticipated sequel to <em>Zero Day: The Game</em>.
            Set in 2147, Earth has become uninhabitable, and humanity's last hope lies deep beneath the ocean in
            the research base <strong>Triton 631</strong>.
          </p>
          <p>
            Play as a young boy waking up alone in eerie corridors, piecing together the crew’s final mission
            to harness geothermal energy. Your actions could save – or doom – humanity.
          </p>
        </Box>

        {/* Sub Zero Download Button */}
        <Button
          rightIcon={<FaDownload />}
          size="lg"
          bgGradient="linear(to-r, blue.400, teal.500)"
          color="white"
          _hover={{ bgGradient: 'linear(to-r, teal.600, blue.700)', transform: 'scale(1.05)' }}
          boxShadow="xl"
          fontFamily="Jersey10"
          textShadow="2px 2px 4px rgba(0, 0, 0, 0.7)"
          onClick={() =>
            window.open('https://apps.apple.com/se/app/sub-zero-the-game/id6736951257', '_blank')
          }
        >
          Download Sub Zero 🚀
        </Button>
      </Box>

      {/* MainCharacterGif Image Above Zero Day Section */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={8} mb={4}>
        <img
          src="/images/MainCharacterGif.gif"
          alt="Main Character Animation"
          style={{ width: '80px', height: 'auto' }}
        />
      </Box>

      {/* Reference to Zero Day: The Game */}
      <Box className="promo-text" maxW="600px" textAlign="center" mt={4} mb={3}>
        <h2 className="section-header" style={{ marginBottom: '20px' }}>
          Discover the Origin: <strong>Zero Day: The Game</strong>
        </h2>
        <p>
          Before diving into <strong>Sub Zero</strong>, experience where it all began. Play{' '}
          <strong>Zero Day</strong>, the original sci-fi puzzle adventure that started humanity's
          journey to survival.
        </p>
      </Box>

      {/* Zero Day Download Button */}
      <Button
        rightIcon={<FaDownload />}
        size="lg"
        bgGradient="linear(to-r, orange.400, pink.500)"
        color="white"
        _hover={{ bgGradient: 'linear(to-r, red.400, yellow.400)', transform: 'scale(1.05)' }}
        boxShadow="xl"
        fontFamily="Jersey10"
        mt={4}
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.7)"
        onClick={() =>
          window.open('https://apps.apple.com/us/app/zero-day-the-game/id6670699407', '_blank')
        }
      >
        Download Zero Day 🚀
      </Button>

      {/* Social Media Icons */}
      <Box mt={8} mb={8} textAlign="center">
        <h2 className="section-header">Follow Us on Social Media</h2>
        <Box mt={4}>
          <Button
            as="a"
            href="https://x.com/zerodaythegame"
            target="_blank"
            leftIcon={<FaTwitter />}
            m={2}
            colorScheme="twitter"
          >
            X (Twitter)
          </Button>
          <Button
            as="a"
            href="https://instagram.com/zerodaythegame"
            target="_blank"
            leftIcon={<FaInstagram />}
            m={2}
            colorScheme="pink"
          >
            Instagram
          </Button>
          <Button
            as="a"
            href="https://www.facebook.com/profile.php?id=61565884776142&mibextid=LQQJ4d"
            target="_blank"
            leftIcon={<FaFacebook />}
            m={2}
            colorScheme="facebook"
          >
            Facebook
          </Button>
        </Box>
      </Box>

      {/* Instagram Feed via SnapWidget */}
      <Box mt={0} mb={0} width="100%" maxW="765px">
        <h2 className="section-header">Latest on Instagram</h2>
        <Box mt={2}>
          <iframe
            src="https://snapwidget.com/embed/1085544"
            className="snapwidget-widget"
            allowTransparency="true"
            frameBorder="0"
            scrolling="no"
            style={{
              border: 'none',
              overflow: 'hidden',
              width: '100%',
              height: '600px',
              display: 'block',
            }}
            title="Posts from Instagram"
          ></iframe>
        </Box>
      </Box>

      <Box height="50px" /> {/* Blank space at the bottom */}
    </Box>
  );
};

export default Home;
